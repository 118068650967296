<template>
  <div class="addAdministrator">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加管理员</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      class="demo-form-inline"
      :model="formList"
      :rules="rules"
      ref="ruleForm"
    >
      <el-form-item label="用户名" prop="userName">
        <el-input
          v-model.trim="formList.userName"
          placeholder="用户名"
        ></el-input>
      </el-form-item>
      <el-form-item label="真实名称" prop="userTrueName">
        <el-input
          v-model.trim="formList.userTrueName"
          placeholder="真实名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="userPassword">
        <el-input
          type="password"
          v-model="formList.userPassword"
          autocomplete="off"
          placeholder="请输入6 到 20 位的数字、字母组合"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="formList.checkPass"
          autocomplete="off"
          placeholder="请确认密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="头像" class="avatar">
        <div class="img" v-show="formList.userHeadImg">
          <img :src="formList.userHeadImg" />
          <div class="el-icon-close" @click="onClose"></div>
        </div>
        <div class="addImg" @click="onSendImg">
          <input type="file" hidden ref="fileRefs" @change="onFileChange" />
          <i class="el-icon-plus"></i>
        </div>
        <span>每张图片不能超过500K，建议尺寸：480 X 480px</span>
      </el-form-item>
      <el-form-item label="电话号码" prop="userPhone">
        <el-input
          v-model="formList.userPhone"
          placeholder="电话号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="角色主键">
        <el-select
          v-model="value"
          placeholder="请选择"
          clearable
          @change="onRoleIdChange"
        >
          <el-option
            v-for="item in roleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio v-model="sexValue" label="0">男</el-radio>
        <el-radio v-model="sexValue" label="1">女</el-radio>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSaveUser">保存</el-button>
        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { roleList } from "../../api/role";
import { addAdmin } from "../../api/administrator";
import { sendFile } from "../../api/sendFile";
import sha1 from "sha1";
export default {
  name: "AddAdministrator",
  data() {
    var validateUName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else if (value.length < 2 || value.length > 6) {
        callback(new Error("请输入2到6位的字符"));
      } else {
        callback();
      }
    };
    var validateTrueUName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入真实用户名"));
      } else if (value.length < 2 || value.length > 6) {
        callback(new Error("请输入2到6位的字符"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      var psw = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!psw.test(value)) {
        callback(new Error("请输入6 到 20 位的数字、字母组合"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      console.log(value, "value");
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formList.userPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validateTelNumber = (rule, value, callback) => {
      var tel = /^1[3|4|5|7|8][0-9]{9}$/;
      if (value === "") {
        callback(new Error("请输入电话号码"));
      } else if (!tel.test(value)) {
        callback(new Error("请输入11位数的电话号码"));
      } else {
        callback();
      }
    };
    return {
      checkPass: "",
      sexValue: "",
      formList: {
        userName: "",
        userTrueName: "",
        userPassword: "",
        userHeadImg: "",
        userPhone: "",
        roleId: null,
        userSex: null,
      },
      roleList: [], //角色列表
      value: "", //角色主键
      src:
        "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      rules: {
        userPassword: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        userPhone: [{ validator: validateTelNumber, trigger: "blur" }],
        userName: [{ validator: validateUName, trigger: "blur" }],
        userTrueName: [{ validator: validateTrueUName, trigger: "blur" }],
      },
    };
  },
  watch: {
    sexValue: function () {
      this.formList.userSex = Number(this.sexValue);
    },
  },
  created() {
    this.getRoleList();
  },
  methods: {
    async getRoleList() {
      const { data } = await roleList({
        currentPage: 1,
        pageSize: 1000,
      });
      data.list.map((item) => {
        (item.label = item.roleName), (item.value = item.roleId);
      });
      this.roleList = data.list;
      console.log(data);
    },
    onRoleIdChange(value) {
      this.formList.roleId = value;
    },
    onSaveUser() {
      console.log(this.$refs.ruleForm);
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const { data } = await addAdmin({
            userName: this.formList.userName,
            userTrueName: this.formList.userTrueName,
            userPassword: sha1(this.formList.userPassword),
            userHeadImg: this.formList.userHeadImg,
            userPhone: this.formList.userPhone,
            roleId: this.formList.roleId,
            userSex: this.formList.userSex,
          });
          console.log(data);
          if (data.code === 0) {
            this.$router.push("/roleList");
            this.$message({
              message: "添加成功",
              type: "success",
            });
          } else {
            this.$message.error("添加失败");
          }
        } else {
          return false;
        }
      });
    },
    onClose() {
      this.formList.userHeadImg = "";
    },
    onSendImg() {
      this.$refs.fileRefs.click();
    },
    async onFileChange(event) {
      // 根据这个 <input> 获取文件的 HTML5 js 对象
      var files = event.target.files;
      if (files && files.length > 0) {
        // 获取目前上传的文件
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        console.log(data, "1233123");
        this.formList.userHeadImg = data.data[0].src;
      }
    },
    sendAvatar() {
      this.$refs.fileRef.click();
    },
  },
};
</script>
<style lang="less" scoped>
.addAdministrator {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/.el-form {
    margin-top: 50px;
    .el-form-item {
      .el-form-item__content {
        .el-form-item__error {
          margin-left: 75px;
        }
      }
    }
    .avatar {
      display: flex;
      align-items: center;
      .el-form-item__content {
        display: flex;
        align-items: center;
        .img {
          position: relative;
          margin: 0 20px;
          height: 100px;
          width: 100px;
          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 5px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
        span {
          margin-left: 30px;
          color: red;
        }
        .el-button {
          margin-right: 20px;
        }
      }
    }
    .el-form-item__label {
      width: 80px;
    }
    .el-input {
      width: 500px;
    }
  }
}
</style>